<template>
  <div class="row justify-center">
    <q-card class="my-card text-center" style="width: 95%">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ name }}</div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <Navbar :logout="logout" />
      </q-card-actions>
      <q-card-section>
        <div class="q-pa-md">
          <q-table
            :title="'Lista sylabusów do akceptacji formalnej - ' + wydzialAdmin"
            :rows="przedmioty"
            :columns="columns"
            :filter="filter"
            row-key="name"
          >
            <template v-slot:top-right>
              <q-input
                borderless
                dense
                debounce="300"
                v-model="filter"
                placeholder="Wyszukaj"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:body-cell-przedmiot="props">
              <q-td :props="props">
                <router-link :to="'/admin/formalna/' + props.row.id">{{
                  props.row.przedmiot
                }}</router-link>
              </q-td>
            </template>
            <template v-slot:body-cell-merytoryczna="props">
              <q-td :props="props">{{
                props.row.akceptacja_merytoryczna
              }}</q-td>
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "../components/Navbar.vue";

export default {
  components: { Navbar },
  data() {
    return {
      name: "Elektroniczna baza sylabusów",
      userId: sessionStorage.employeeId,
      wydzialAdmin: sessionStorage.wydzialAdmin,
      przedmioty: [],
      kierunki: [],
      wydzialy: [],
      filter: "",
      columns: [
        {
          name: "przedmiot",
          required: true,
          label: "Lista przedmiotów",
          align: "left",
          field: (row) => row.przedmiot,
          sortable: true,
        },
        {
          name: "kierunek",
          required: true,
          label: "Kierunek",
          align: "left",
          field: (row) => row.kierunki,
          sortable: true,
        },
        {
          name: "lang",
          required: true,
          label: "Wersja językowa",
          align: "center",
          field: (row) => row.lang_version,
          sortable: true,
        },
        {
          name: "rok",
          required: true,
          label: "Rok",
          align: "left",
          field: (row) => row.rok,
          sortable: true,
        },

        {
          name: "data",
          required: true,
          label: "Data dodania",
          align: "left",
          field: (row) => row.data,
          sortable: true,
        },
        {
          name: "dodal",
          required: true,
          label: "Dodał",
          align: "center",
          field: (row) => row.email,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    checkRoute() {
      if (!sessionStorage.employeeId) {
        this.$router.push({
          name: "Login",
        });
      }
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  mounted() {
    axios
      .get(
        `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/formalny.php?mail=${sessionStorage.employeeMail}`
      )
      .then((response) => {
        let data = response.data;
        if (data[0] === "redirect") {
          this.$router.push("/login");
        }
        for (let i = 0; i < Object.keys(data).length; i++) {
          this.przedmioty.push(data[i]);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  },
  created() {
    this.checkRoute();
  },
};
</script>

<style>
.q-td.text-left {
  white-space: pre-wrap;
}
</style>
