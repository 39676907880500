<template>
  <div class="row justify-center">
    <Spinner :showSpinner="showSpinner" :language="language" />
    <q-card v-if="!showSpinner" class="my-card text-center">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ name }}</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <h5>
          Logowanie za pomocą<br />
          Centralnego Systemu Autoryzacji
        </h5>
        <q-form @submit.prevent="validate" class="q-gutter-md">
          <div class="row justify-center">
            <div style="min-width: 200px">
              <q-input
                v-model="loginForm.login.field"
                label="imie.nazwisko"
                :rules="[
                  (val) => (val && val.length > 0) || 'Proszę podać login',
                ]"
              >
                <template v-slot:prepend>
                  <q-icon name="account_circle" /> </template
              ></q-input>
              <q-input
                v-model="loginForm.password.field"
                label="hasło"
                type="password"
                :rules="[
                  (val) => (val && val.length > 0) || 'Proszę podać hasło',
                ]"
                ><template v-slot:prepend> <q-icon name="lock" /> </template
              ></q-input>
            </div>
          </div>
          <q-banner v-if="error" inline-actions class="text-white bg-red">
            Błędne dane logowania. Proszę spróbować ponownie.
          </q-banner>
          <q-card-actions align="center">
            <q-btn class="bg-primary text-white" type="submit"
              >Zaloguj się</q-btn
            >
          </q-card-actions>
        </q-form>
      </q-card-section>
      <q-card-section>
        <p>
          Zakładanie konta oraz zmiana hasła odbywa się poprzez elektroniczny
          formularz na stronie
          <a
            href="https://csa.umw.edu.pl/"
            target="_blank"
            style="text-decoration: none"
            >csa.umw.edu.pl</a
          >
        </p>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import { useQuasar } from "quasar";
import Spinner from "../components/Spinner.vue";
export default {
  components: { Spinner },
  setup() {
    const $q = useQuasar();

    return {
      showNotif(position) {
        $q.notify({
          message:
            "Błąd logowania. Sprawdź poprawność danych i spróbuj ponownie.",
          position: "top-right",
          color: "negative",
        });
      },
    };
  },
  data() {
    return {
      name: "Elektroniczna baza sylabusów",
      API: "https://app.umw.edu.pl/api/ldap/api.php",
      language: "pl",
      loginForm: {
        valid: false,
        login: {
          field: "",
        },
        password: {
          field: "",
        },
      },
      showSpinner: false,
      user_id: "",
      user_type: "",
      error: "",
    };
  },
  methods: {
    saveFormData() {
      if (this.loginForm.valid) {
        return (this.readyLoginForm = {
          login: this.loginForm.login.field,
          password: this.loginForm.password.field,
          api_url: "https://app.umw.edu.pl/serwis/login.php",
        });
      }
    },

    validate() {
      this.showSpinner = true;
      const readyLoginData = new FormData();
      readyLoginData.append("login", this.loginForm.login.field);
      readyLoginData.append("pwd", this.loginForm.password.field);
      readyLoginData.append(
        "api_url",
        "https://app.umw.edu.pl/serwis/login.php"
      );
      axios
        .post(this.API, readyLoginData, {})
        .then((res) => this.checkResponse(res))
        .catch((error) => {
          this.showSpinner = false;
          this.loginForm.login.field = "";
          this.loginForm.password.field = "";
          this.showNotif();
        });
    },
    checkResponse(res) {
      if (res.data["data"][0]["employeenumber"][0]) {
        this.user_id = res.data["data"][0]["employeenumber"][0];
        this.user_mail = res.data["data"][0]["mail"][0];
        this.user_name = res.data["data"][0]["cn"][0];
        sessionStorage.employeeId = this.user_id;
        sessionStorage.employeeMail = this.user_mail;
        sessionStorage.employeeName = this.user_name;
        sessionStorage.isLoggedIn = true;
        this.$router.push({
          name: "Admin",
          params: {
            userId: this.user_id,
            userMail: this.user_mail,
            userName: this.user_name,
          },
        });
        this.showSpinner = false;
      } else {
        this.showSpinner = false;
        sessionStorage.clear();
      }
      this.loginForm.login.field = "";
      this.loginForm.password.field = "";
    },
    checkRoute() {
      if (sessionStorage.employeeId && sessionStorage.employeeMail) {
        this.$router.push({
          name: "Admin",
          params: {
            userId: sessionStorage.employeeId,
            userMail: sessionStorage.employeeMail,
          },
        });
      }
    },
  },
  created() {
    this.checkRoute();
  },
};
</script>