<template>
  <div align="center">
    <q-btn
      class="bg-blue-grey-1"
      style="margin-right: 10px"
      href="https://www.umw.edu.pl/centrum-kultury-jakosci-ksztalcenia-sylabus-materialy-szkoleniowe"
      target="_blank"
      >Materiały szkoleniowe</q-btn
    >
    <q-btn
      class="bg-blue-grey-1"
      style="margin-right: 10px"
      href="https://www.umw.edu.pl/centrum-kultury-jakosci-ksztalcenia-sylabus-faq"
      target="_blank"
      >FAQ</q-btn
    >
  </div>
  <q-btn class="bg-primary text-white q-mx-md q-my-md" to="/admin">Wróć</q-btn>
  <q-btn class="bg-primary text-white q-mx-md q-my-md" @click="logout"
    >Wyloguj się</q-btn
  >
</template>

<script>
export default {
  props: ["logout"],
};
</script>

<style>
</style>