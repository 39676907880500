<template>
  <div class="row justify-center" style="padding-bottom: 200px">
    <q-card class="my-card text-center" style="width: 95%">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ nazwa }}</div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <Navbar :logout="logout" />
      </q-card-actions>
      <q-form @submit.prevent="submitFile">
        <q-card-section>
          <h4>Dodawanie sylabusów</h4>
          <p>Pola pojawiają się wraz z dokonywanymi wyborami.</p>
          <div class="row justify-center q-mt-md">
            <div style="min-width: 400px">
              <q-select
                v-model="form.wersja_api"
                :options="wersje_api"
                label="Sylabus dla"
                emit-value
                map-options
              />
              <q-select
                v-if="form.wersja_api"
                v-model="form.wydzial"
                :options="wydzialy"
                label="Wydział"
              />
              <q-select
                v-if="form.wydzial"
                v-model="form.kierunek"
                :options="kierunki"
                label="Kierunek"
              />
              <q-select
                v-if="form.kierunek"
                v-model="form.poziom"
                :options="poziomy"
                label="Poziom kształcenia"
              />
              <q-select
                v-if="form.poziom"
                v-model="form.forma"
                :options="formy"
                label="Forma studiów"
              />

              <q-select
                v-if="form.forma"
                v-model="form.rok"
                :options="roki"
                label="Rok"
              />
              <q-select
                v-if="form.rok"
                v-model="form.przedmiot"
                :options="przedmioty"
                label="Nazwa przedmiotu"
              />
              <br />
              <div class="q-gutter-sm">
                <q-radio
                  v-if="form.przedmiot && form.wersja_api !== 'pl'"
                  v-model="form.version"
                  val="pl"
                  label="Wersja polska"
                />
                <q-radio
                  v-if="form.przedmiot && form.wersja_api !== 'pl'"
                  v-model="form.version"
                  val="en"
                  label="Wersja angielska"
                />
              </div>
              <div
                class="q-pa-md q-gutter-sm"
                style="max-width: 500px"
                v-if="istnieje"
              >
                <q-banner inline-actions rounded class="bg-orange text-white">
                  {{ message }}
                </q-banner>
              </div>
              <br />
              <q-file
                outlined
                v-model="uploadfile"
                label="Załącz plik w formacie pdf"
                v-if="form.przedmiot && !istnieje"
                accept=".pdf"
              >
                <template v-slot:prepend>
                  <q-icon name="attach_file" />
                </template>
              </q-file>
              <div class="q-pa-md" v-if="upload">
                <p>Dodawanie...</p>
                <q-linear-progress indeterminate />
                <q-linear-progress query color="warning" class="q-mt-sm" />
              </div>
            </div>
          </div>
        </q-card-section>
        <q-btn
          class="bg-primary text-white q-mb-md"
          type="submit"
          :disable="!uploadButton || istnieje"
          >Dodaj sylabus</q-btn
        >
      </q-form>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import { useQuasar } from "quasar";
import Navbar from "../components/Navbar.vue";

export default {
  props: ["nazwa"],
  components: { Navbar },
  setup() {
    const $q = useQuasar();

    return {
      showNotif(position) {
        $q.notify({
          message: "Plik został pomyślnie dodany.",
          position: "bottom",
          color: "positive",
        });
      },
      showNotifWrong(position) {
        $q.notify({
          message: "Nie udało się dodać pliku.",
          position: "bottom",
          color: "negative",
        });
      },
    };
  },
  data() {
    return {
      userId: sessionStorage.employeeId,
      userMail: sessionStorage.employeeMail,
      userName: sessionStorage.employeeName,
      uploadfile: "",
      upload: false,
      uploadButton: false,
      form: {
        version: "pl",
        wersja_api: "",
        wydzial: "",
        kierunek: "",
        forma: "",
        poziom: "",
        przedmiot: "",
        link: "",
        rok: "",
        komentarz: "",
        status: "",
        dodal: sessionStorage.employeeName,
        dodal_mail: sessionStorage.employeeMail,
      },
      istnieje: false,
      message: "",
      wydzialy: [],
      kierunki: [],
      poziomy: [],
      formy: [],
      roki: [],
      przedmioty: [],
      formy_przedmiotu: [],
      wersje_api: [
        { label: "Studenta lub doktoranta polskiego", value: "pl" },
        { label: "Studenta ED", value: "ed" },
      ],
    };
  },
  watch: {
    "form.wersja_api": function () {
      this.uploadButton = false;
      axios
        .get(
          this.form.wersja_api === "pl"
            ? "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php"
            : "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php"
        )
        .then((response) => {
          let data = response.data.wydzialy;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.wydzialy.push(Object.keys(data)[i]);
          }
          this.showSpinner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      if (this.form.wersja_api) {
        this.istnieje = false;
        this.uploadButton = false;
        this.uploadfile = "";
        this.form.wydzial = "";
        this.wydzialy = [];
        this.kierunki = [];
        this.form.kierunek = "";
        this.poziomy = [];
        this.form.poziom = "";
        this.form.forma = "";
        this.formy = [];
        this.form.rok = "";
        this.roki = [];
        this.form.przedmiot = "";
        this.przedmioty = [];
      }
    },
    "form.wydzial": function () {
      this.uploadButton = false;
      axios
        .get(
          this.form.wersja_api === "pl"
            ? "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php"
            : "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php"
        )
        .then((response) => {
          let data = response.data.wydzialy[this.form.wydzial].kierunki;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.kierunki.push(Object.keys(data)[i]);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      if (this.form.wydzial) {
        this.istnieje = false;
        this.uploadButton = false;
        this.uploadfile = "";
        this.kierunki = [];
        this.form.kierunek = "";
        this.poziomy = [];
        this.form.poziom = "";
        this.form.forma = "";
        this.formy = [];
        this.form.rok = "";
        this.roki = [];
        this.form.przedmiot = "";
        this.przedmioty = [];
      }
    },
    "form.kierunek": function () {
      this.uploadButton = false;
      axios
        .get(
          this.form.wersja_api === "pl"
            ? "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php"
            : "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php"
        )
        .then((response) => {
          let data =
            response.data.wydzialy[this.form.wydzial].kierunki[
              this.form.kierunek
            ].poziomy;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.poziomy.push(Object.keys(data)[i]);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      if (this.form.kierunek) {
        this.istnieje = false;
        this.uploadButton = false;
        this.uploadfile = "";
        this.poziomy = [];
        this.form.poziom = "";
        this.form.forma = "";
        this.formy = [];
        this.form.rok = "";
        this.roki = [];
        this.form.przedmiot = "";
        this.przedmioty = [];
      }
    },
    "form.poziom": function () {
      this.uploadButton = false;
      axios
        .get(
          this.form.wersja_api === "pl"
            ? "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php"
            : "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php"
        )
        .then((response) => {
          let data =
            response.data.wydzialy[this.form.wydzial].kierunki[
              this.form.kierunek
            ].poziomy[this.form.poziom].forma;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.formy.push(Object.keys(data)[i]);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      if (this.form.poziom) {
        this.istnieje = false;
        this.uploadButton = false;
        this.uploadfile = "";
        this.form.forma = "";
        this.formy = [];
        this.form.rok = "";
        this.roki = [];
        this.form.przedmiot = "";
        this.przedmioty = [];
      }
    },
    "form.forma": function () {
      this.uploadButton = false;
      axios
        .get(
          this.form.wersja_api === "pl"
            ? "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php"
            : "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php"
        )
        .then((response) => {
          let data =
            response.data.wydzialy[this.form.wydzial].kierunki[
              this.form.kierunek
            ].poziomy[this.form.poziom].forma[this.form.forma].rok;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.roki.push(Object.keys(data)[i]);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      if (this.form.forma) {
        this.istnieje = false;
        this.uploadButton = false;
        this.uploadfile = "";
        this.form.rok = "";
        this.roki = [];
        this.form.przedmiot = "";
        this.przedmioty = [];
      }
    },
    "form.rok": function () {
      this.uploadButton = false;
      axios
        .get(
          this.form.wersja_api === "pl"
            ? "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php"
            : "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php"
        )
        .then((response) => {
          let data =
            response.data.wydzialy[this.form.wydzial].kierunki[
              this.form.kierunek
            ].poziomy[this.form.poziom].forma[this.form.forma].rok[
              this.form.rok
            ].przedmiot;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.przedmioty.push(Object.keys(data)[i]);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      if (this.form.rok) {
        this.istnieje = false;
        this.uploadButton = false;
        this.uploadfile = "";
        this.form.przedmiot = "";
        this.przedmioty = [];
      }
    },
    "form.przedmiot": function () {
      this.istnieje = false;
      this.uploadButton = false;
      this.uploadfile = "";
      axios
        .get(
          `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/verify.php?przedmiot=${this.form.przedmiot}&wydzial=${this.form.wydzial}&kierunek=${this.form.kierunek}&poziom=${this.form.poziom}&forma=${this.form.forma}&wersja_api=${this.form.wersja_api}&version=${this.form.version}&rok=${this.form.rok}`
        )
        .then((res) => {
          if (res.data.is_syllabus === true) {
            this.istnieje = true;
            this.message = res.data.message;
          }
        })
        .catch((err) => {});
    },
    uploadfile: function () {
      this.uploadButton = true;
    },
    uploadButton: function () {
      if (
        this.form.wersja_api.length < 1 ||
        this.form.wydzial.length < 1 ||
        this.form.kierunek.length < 1 ||
        this.form.forma.length < 1 ||
        this.form.poziom.length < 1 ||
        this.form.rok.length < 1 ||
        this.form.przedmiot.length < 1
      ) {
        this.uploadButton = false;
      }
    },
  },
  methods: {
    submitFile() {
      this.uploadButton = false;
      this.upload = true;
      let formData = new FormData();
      formData.append("uploadfile", this.uploadfile);
      formData.append("form", JSON.stringify(this.form));
      formData;
      axios
        .post("https://archiwum-sylabusy.umw.edu.pl/api/pracownik/add.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.uploadButton = true;
          this.upload = false;
          this.kierunki = [];
          this.form.wydzial = "";
          this.form.kierunek = "";
          this.poziomy = [];
          this.form.poziom = "";
          this.form.forma = "";
          this.formy = [];
          this.form.rok = "";
          this.roki = [];
          this.form.przedmiot = "";
          this.przedmioty = [];
          this.uploadfile = "";
          this.form.wersja_api = "";
          this.form.version = "pl";
          this.showNotif();
        })
        .catch((e) => {
          this.uploadButton = true;
          this.upload = false;
          this.kierunki = [];
          this.form.wydzial = "";
          this.form.kierunek = "";
          this.poziomy = [];
          this.form.poziom = "";
          this.form.forma = "";
          this.formy = [];
          this.form.rok = "";
          this.roki = [];
          this.form.przedmiot = "";
          this.przedmioty = [];
          this.uploadfile = "";
          this.form.wersja_api = "";
          this.form.version = "pl";
          this.showNotifWrong();
        });
    },
    // checkRoute() {
    //   if (!sessionStorage.employeeId) {
    //     this.$router.push({
    //       name: "Login",
    //     });
    //   }
    // },
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  //   created() {
  //     this.checkRoute();
  //   },
};
</script>

<style></style>
