<template>
  <q-page
    v-if="showSpinner"
    class="row justify-center"
    style="padding-top: 200px"
  >
    <div class="row">
      <div class="col-12 text-center">
        <q-spinner
          class="q-mb-md q-mt-md"
          color="primary"
          size="5em"
          :thickness="5"
        />
        <p class="q-mt-md text-h6">
          {{
            language === "pl" ? "Ładowanie informacji..." : "Loading data..."
          }}
        </p>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  props: ["showSpinner", "language"],
};
</script>

<style>
</style>