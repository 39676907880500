<template>
  <div class="row justify-center">
    <q-card class="my-card text-center" style="width: 95%; min-height: 300px">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ name }}</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <q-card-actions align="right">
          <div align="center">
            <q-btn
              class="bg-blue-grey-1"
              style="margin-right: 10px"
              href="https://www.umw.edu.pl/centrum-kultury-jakosci-ksztalcenia-sylabus-materialy-szkoleniowe"
              target="_blank"
              >Materiały szkoleniowe</q-btn
            >
            <q-btn
              class="bg-blue-grey-1"
              style="margin-right: 10px"
              href="https://www.umw.edu.pl/centrum-kultury-jakosci-ksztalcenia-sylabus-faq"
              target="_blank"
              >FAQ</q-btn
            >
          </div>
          <q-btn class="bg-primary text-white q-mx-md q-my-md" @click="logout"
            >Wyloguj się</q-btn
          >
        </q-card-actions>
        <q-btn
          class="bg-primary text-white q-mr-md q-my-md"
          :to="{ name: 'Dodaj' }"
          >Dodaj sylabus</q-btn
        >
        <q-btn class="bg-primary text-white q-mr-md" :to="{ name: 'Lista' }"
          >Moje sylabusy</q-btn
        >
        <!-- Sprawdzenie czy mail użytkownika znajduje się w obiekcie -->
        <q-btn
          v-if="formalnyUser"
          class="bg-primary text-white q-mr-md q-my-md"
          :to="{ name: 'Formalna' }"
          >Sylabusy do akceptacji formalnej</q-btn
        >
        <q-btn
          v-if="merytorycznyUser"
          class="bg-primary text-white q-mr-md q-my-md"
          :to="{ name: 'Merytoryczna' }"
          >Sylabusy do akceptacji merytorycznej</q-btn
        >
        <q-btn
          v-if="dziekanUser"
          class="bg-primary text-white q-mr-md q-my-md"
          :to="{ name: 'Dziekan' }"
          >Sylabusy do akceptacji Dziekana</q-btn
        >
        <q-btn
          v-if="changeUser"
          class="bg-primary text-white q-mr-md q-my-md"
          :to="{ name: 'Delete' }"
          >Usuwanie sylabusów</q-btn
        >
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "Elektroniczna baza sylabusów",
      userId: sessionStorage.employeeId,
      userMail: sessionStorage.employeeMail,
      userName: sessionStorage.employeeName,
      merytoryczny: [],
      merytorycznyUser: false,
      formalnyUser: false,
      dziekanUser: false,
      changeUser: false,
      nowy: "",
      wydzial: "",
      formalny: [],
      dziekan: [],
      usuwanie: [
        "maciej.halon@umw.edu.pl",
        "piotr.gil@umw.edu.pl",
        "wiktor.palus@umw.edu.pl",
        "iwona.janus@umw.edu.pl",
        "justyna.jezewska@umw.edu.pl",
        "katarzyna.kusiak@umw.edu.pl",
        "maria.janki-grzebieluch@umw.edu.pl",
        "katarzyna.czerniawska@umw.edu.pl",
        "marzena.patyna-sieniuta@umw.edu.pl",
      ],
    };
  },
  methods: {
    checkRoute() {
      if (!sessionStorage.employeeId) {
        this.$router.push({
          name: "login",
        });
      }
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  mounted() {
    if (this.usuwanie.includes(this.userMail)) {
      this.changeUser = true;
    }
    axios
      .get("https://archiwum-sylabusy.umw.edu.pl/api/pracownik/accept.php")
      .then((response) => {
        let data = response.data.merytoryczny;
        for (let i = 0; i < data.length; i++) {
          if (data[i][this.userMail]) {
            this.wydzial = data[i][this.userMail];
            sessionStorage.wydzialAdmin = this.wydzial;
          }
          this.merytoryczny.push(data[i]);
        }
        this.merytorycznyUser = this.merytoryczny
          .map((v) => Object.keys(v).join(","))
          .join(",");
        if (this.merytorycznyUser.includes(this.userMail)) {
          this.merytorycznyUser = true;
        } else {
          this.merytorycznyUser = false;
        }
        let data2 = response.data.formalny;
        for (let i = 0; i < data2.length; i++) {
          if (data2[i][this.userMail]) {
            this.wydzial = data2[i][this.userMail];
            sessionStorage.wydzialAdmin = this.wydzial;
          }
          this.formalny.push(data2[i]);
        }
        this.formalnyUser = this.formalny
          .map((v) => Object.keys(v).join(","))
          .join(",");
        if (this.formalnyUser.includes(this.userMail)) {
          this.formalnyUser = true;
        } else {
          this.formalnyUser = false;
        }
        let data3 = response.data.dziekan;
        for (let i = 0; i < data3.length; i++) {
          if (data3[i][this.userMail]) {
            this.wydzial = data3[i][this.userMail];
            sessionStorage.wydzialAdmin = this.wydzial;
          }
          this.dziekan.push(data3[i]);
        }
        this.dziekanUser = this.dziekan
          .map((v) => Object.keys(v).join(","))
          .join(",");
        if (this.dziekanUser.includes(this.userMail)) {
          this.dziekanUser = true;
        } else {
          this.dziekanUser = false;
        }
      })
      .catch((e) => {});
  },
  created() {
    this.checkRoute();
  },
};
</script>
