<template>
  <q-layout>
    <q-page-container>
      <div class="row justify-center">
        <q-img
          src="./assets/logo.png"
          style="max-height: 400px; max-width: 400px"
        />
      </div>
      <router-view :nazwa="nazwa" :nazwaEN="nazwaEN" />
    </q-page-container>
    <q-footer style="max-height: 20px" class="text-center bg-grey-1 text-grey">
      <p>v3.1</p>
    </q-footer>
  </q-layout>
</template>

<script>
export default {
  data() {
    return {
      nazwa: "Elektroniczna baza sylabusów",
      nazwaEN: "Electronic database of syllabuses",
    };
  },
};
</script>
