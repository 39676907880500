<template>
  <div class="row justify-center">
    <q-card class="my-card text-center" style="width: 95%; min-height: 300px">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ name }}</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <q-card-actions align="right">
          <Navbar :logout="logout" />
        </q-card-actions>
        <div class="q-gutter-md" style="max-width: 500px; margin: 0 auto">
          <q-input
            v-model="text"
            label="Link do sylabusa (Cloud)"
            hint="np. https://cloud.umw.edu.pl/index.php/s/dPpWPnNS3SZ9aRk"
          />
          <q-btn class="bg-primary text-white" @click="show()" :disable="!text"
            >Usuń</q-btn
          >
        </div>
        <div class="q-pa-md q-ma-lg">
          <q-table
            title="Usunięte sylabusy"
            :rows="deletedSyllabuses"
            :columns="columns"
            :filter="filter"
            row-key="id"
          >
            <template v-slot:top-right>
              <q-input
                borderless
                dense
                debounce="300"
                v-model="filter"
                placeholder="Wyszukaj"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input> </template
          ></q-table>
        </div>
        <q-dialog ref="dialog" @hide="onDialogHide">
          <q-card class="q-dialog-plugin" style="padding: 20px">
            <div v-if="przedmiot">
              <p style="text-align: center">
                <b>Czy na pewno chcesz usunąć sylabus?</b>
              </p>
              <p><b>Nazwa sylabusa:</b> {{ przedmiot.subject }}</p>
              <p><b>Wydział:</b> {{ przedmiot.faculty }}</p>
              <p><b>Kierunek:</b> {{ przedmiot.field_of_study }}</p>
              <p><b>Poziom:</b> {{ przedmiot.degree }}</p>
              <p><b>Forma:</b> {{ przedmiot.form_of_study }}</p>
              <p><b>Rok:</b> {{ przedmiot.year }}</p>
              <p><b>Kto dodał:</b> {{ przedmiot.email }}</p>
              <p><b>Data dodania:</b> {{ przedmiot.data }}</p>
            </div>
            <div v-if="!przedmiot">
              <p>Nie znaleziono sylabusa.</p>
            </div>
            <q-card-actions align="center">
              <q-btn
                v-if="przedmiot"
                label="Zatwierdź"
                class="bg-primary text-white"
                @click="deleteSylabus"
              />
              <q-btn
                label="Zamknij okno"
                class="bg-primary text-white"
                @click="onCancelClick"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import { useQuasar } from "quasar";
import Navbar from "../components/Navbar.vue";

export default {
  components: { Navbar },
  setup() {
    const $q = useQuasar();

    return {
      showNotif(position) {
        $q.notify({
          message: `Sylabus ${this.przedmiot.subject} został pomyślnie usunięty.`,
          position: "bottom",
          color: "positive",
        });
      },
      showNotifWrong(position) {
        $q.notify({
          message: "Nie udało się usunąć sylabusa.",
          position: "bottom",
          color: "negative",
        });
      },
    };
  },
  data() {
    return {
      name: "Elektroniczna baza sylabusów",
      userId: sessionStorage.employeeId,
      userMail: sessionStorage.employeeMail,
      userName: sessionStorage.employeeName,
      text: "",
      przedmiot: "",
      deletedSyllabuses: [],
      columns: [],
      filter: "",
    };
  },
  methods: {
    show(e) {
      this.$refs.dialog.show();
      this.przedmiot = "";
      axios
        .get(
          `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/find.php?email=${this.userMail}&url_find=${this.text}`
        )
        .then((response) => {
          this.przedmiot = response.data.przedmioty[0];
          this.text = "";
        })
        .catch((e) => {});
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit("hide");
    },
    onCancelClick() {
      // we just need to hide the dialog
      this.przedmiot = "";
      this.text = "";
      this.hide();
    },
    deleteSylabus() {
      axios
        .delete(
          `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/remove.php?email=${this.userMail}&remove_id=${this.przedmiot.id}`
        )
        .then((response) => {
          this.showNotif();
        })
        .catch((e) => {
          this.showNotifWrong();
        });
      this.hide();
    },
    checkRoute() {
      if (!sessionStorage.employeeId) {
        this.$router.push({
          name: "login",
        });
      }
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  mounted() {
    axios
      .get(
        `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/deleted.php?email=${this.userMail}`
      )
      .then((res) => {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          this.deletedSyllabuses.push(data[i]);
        }
        this.columns = [
          {
            name: "przedmiot",
            label: "Przedmiot",
            align: "left",
            field: (row) => row.subject,
            sortable: true,
          },
          {
            name: "kierunek",
            label: "Kierunek",
            align: "left",
            field: (row) => row.field_of_study,
            sortable: true,
          },
          {
            name: "forma",
            label: "Forma studiów",
            align: "left",
            field: (row) => row.form_of_study,
            sortable: true,
          },
          {
            name: "rok",
            label: "Rok studiów",
            align: "center",
            field: (row) => row.year,
            sortable: true,
          },
          {
            name: "link",
            label: "Link do sylabusa",
            align: "left",
            field: (row) => row.url,
            sortable: true,
          },
        ];
      })
      .catch((err) => {});
  },
  created() {
    this.checkRoute();
  },
};
</script>
