import { createRouter, createWebHistory } from "vue-router";
import Materialy from "../views/Materialy.vue";
import Wydzial from "../views/Wydzial.vue";
import Kierunek from "../views/Kierunek.vue";
import Poziom from "../views/Poziom.vue";
import Forma from "../views/Forma.vue";
import Rok from "../views/Rok.vue";
import Login from "../views/Login.vue";
import Admin from "../views/Admin.vue";
import Delete from "../views/Delete.vue";
import Dodaj from "../views/Dodaj.vue";
import Lista from "../views/Lista.vue";
import Merytoryczna from "../views/Merytoryczna.vue";
import Formalna from "../views/Formalna.vue";
import Dziekan from "../views/Dziekan.vue";
import AkceptacjaMerytoryczna from "../views/AkceptacjaMerytoryczna.vue";
import AkceptacjaFormalna from "../views/AkceptacjaFormalna.vue";
import AkceptacjaDziekan from "../views/AkceptacjaDziekan.vue";

// function guardMyroute(to, from, next){
//   let isAuthenticated= false;
//   if(sessionStorage.getItem('isLoggedIn')){
//    console.log('2');
//    isAuthenticated = true;
//    next()
//   }
//   else
//   console.log('3');
//    isAuthenticated= false;
//    next('/login')
//  if(isAuthenticated) {
//    console.log('4');
//    next(); // allow to enter route
//   } else{
//    console.log('5');
//    next('/login'); // go to '/login';
//   }
//  }

function guardMyroute(to, from, next) {
  let isAuthenticated = false;
  if (sessionStorage.getItem("isLoggedIn")) {
    isAuthenticated = true;
  }
  if (isAuthenticated) {
    console.log("1");
    next();
  } else {
    console.log("2");
    next("/login");
  }
}

const userMail = sessionStorage.getItem("employeeMail");
const usuwanie = [
  "maciej.halon@umw.edu.pl",
  "piotr.gil@umw.edu.pl",
  "wiktor.palus@umw.edu.pl",
  "iwona.janus@umw.edu.pl",
  "justyna.jezewska@umw.edu.pl",
  "katarzyna.kusiak@umw.edu.pl",
  "maria.janki-grzebieluch@umw.edu.pl",
  "katarzyna.czerniawska@umw.edu.pl",
  "marzena.patyna-sieniuta@umw.edu.pl",
];

function guardDelete(to, from, next) {
  if (usuwanie.includes(userMail)) {
    next();
  } else {
    next("/admin");
  }
}

const routes = [
  {
    path: "/",
    redirect: "/pl",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/delete",
    name: "Delete",
    component: Delete,
    beforeEnter: guardDelete,
  },
  {
    path: "/admin/dodaj",
    name: "Dodaj",
    component: Dodaj,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/lista",
    name: "Lista",
    component: Lista,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/merytoryczna",
    name: "Merytoryczna",
    component: Merytoryczna,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/formalna",
    name: "Formalna",
    component: Formalna,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/dziekan",
    name: "Dziekan",
    component: Dziekan,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/merytoryczna/:id",
    name: "AkceptacjaMerytoryczna",
    component: AkceptacjaMerytoryczna,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/formalna/:id",
    name: "AkceptacjaFormalna",
    component: AkceptacjaFormalna,
    beforeEnter: guardMyroute,
  },
  {
    path: "/admin/dziekan/:id",
    name: "AkceptacjaDziekan",
    component: AkceptacjaDziekan,
    beforeEnter: guardMyroute,
  },
  {
    path: "/:language",
    name: "materialyPL",
    component: Materialy,
    props: true,
  },
  {
    path: "/:language/:wydzial",
    name: "Wydzial",
    component: Wydzial,
    props: true,
  },
  {
    path: "/:language/:wydzial/:kierunek",
    name: "Kierunek",
    component: Kierunek,
  },
  {
    path: "/:language/:wydzial/:kierunek/:poziom",
    name: "Poziom",
    component: Poziom,
  },
  {
    path: "/:language/:wydzial/:kierunek/:poziom/:forma",
    name: "Forma",
    component: Forma,
  },
  {
    path: "/:language/:wydzial/:kierunek/:poziom/:forma/:rok",
    name: "Rok",
    component: Rok,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
