<template>
  <div class="row justify-center">
    <q-card
      class="my-card text-center"
      style="width: 100%; margin-bottom: 20px"
    >
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ name }}</div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <Navbar :logout="logout" />
      </q-card-actions>
      <div class="row" style="margin-left: 33px">
        <q-btn @click="filtry = !filtry">Pokaż filtry</q-btn>
      </div>
      <div class="row" v-if="filtry">
        <div class="column" style="margin: 20px">
          <p><b>Kierunki</b></p>
          <q-checkbox
            v-for="oneKierunek in kierunki"
            :key="oneKierunek"
            v-model="kierunkiDisplay"
            :val="oneKierunek"
            :label="oneKierunek"
            @click="testowaFunkcja(oneKierunek)"
          />
        </div>
        <div class="column" style="margin: 20px">
          <p><b>Wersja językowa</b></p>
          <q-checkbox
            v-for="oneLang in lang"
            :key="oneLang"
            v-model="langDisplay"
            :val="oneLang"
            :label="oneLang"
            @click="testowaFunkcja(oneKierunek)"
          />
        </div>
        <div class="column" style="margin: 20px">
          <p><b>Poziom studiów</b></p>
          <q-checkbox
            v-for="onePoziom in poziom"
            :key="onePoziom"
            v-model="poziomDisplay"
            :val="onePoziom"
            :label="onePoziom"
            @click="testowaFunkcja(oneKierunek)"
          />
        </div>
      </div>
      <q-card-section>
        <div class="q-pa-md">
          <q-table
            title="
              Moje sylabusy
            "
            :rows="przedmioty"
            :columns="columns"
            :filter="filter"
            row-key="name"
            :loading="loading"
          >
            <template v-slot:top-right>
              <q-input
                borderless
                dense
                debounce="300"
                v-model="filter"
                placeholder="Wyszukaj"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:body-cell-przedmiot="props">
              <q-td :props="props">
                <a :href="props.row.url" target="_blank">{{
                  props.row.subject
                }}</a>
              </q-td>
            </template>

            <template v-slot:body-cell-formalna="props">
              <q-td :props="props" v-if="props.row.formal_acceptance_at === '1'"
                ><span class="material-icons md-36" style="color: green">
                  done
                </span></q-td
              >
              <q-td
                :props="props"
                v-if="props.row.formal_acceptance_at === '0'"
              ></q-td>
              <q-td
                :props="props"
                v-if="props.row.formal_acceptance_at === '99'"
                ><span class="material-icons md-36" style="color: red">
                  close
                </span></q-td
              >
            </template>
            <template v-slot:body-cell-merytoryczna="props">
              <q-td
                :props="props"
                v-if="props.row.substantive_acceptance_at === '1'"
                ><span class="material-icons md-36" style="color: green">
                  done
                </span></q-td
              >
              <q-td
                :props="props"
                v-if="props.row.substantive_acceptance_at === '0'"
              ></q-td>
              <q-td
                :props="props"
                v-if="props.row.substantive_acceptance_at === '99'"
                ><span class="material-icons md-36" style="color: red">
                  close
                </span></q-td
              >
            </template>
            <template v-slot:body-cell-dziekan="props">
              <q-td :props="props" v-if="props.row.final_acceptance_at === '1'"
                ><span class="material-icons md-36" style="color: green">
                  done
                </span></q-td
              >
              <q-td
                :props="props"
                v-if="props.row.final_acceptance_at === '0'"
              ></q-td>
              <q-td :props="props" v-if="props.row.final_acceptance_at === '99'"
                ><span class="material-icons md-36" style="color: red">
                  close
                </span></q-td
              >
            </template>
            <template v-slot:body-cell-szczegoly="props">
              <q-td :props="props">
                <q-btn
                  @click="show(props.row.komentarz)"
                  v-if="props.row.komentarz"
                  >Szczegóły</q-btn
                >
              </q-td>
              <q-dialog ref="dialog" @hide="onDialogHide">
                <q-card class="q-dialog-plugin" style="padding: 20px">
                  <p>
                    {{ props.row.rejected_by }}<br />{{ props.row.rejected_at }}
                  </p>
                  <p>
                    {{ message }}
                  </p>
                  <q-card-actions align="right">
                    <q-btn
                      label="Zamknij okno"
                      class="bg-primary text-white"
                      @click="onCancelClick"
                    />
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </template>
          </q-table>

          <div class="text-left q-pa-sm">
            <p><b>Legenda</b></p>
            <p>Puste pole - oczekuje na akceptację</p>
            <p>
              <span class="material-icons md-36" style="color: green">
                done
              </span>
              - Zaakceptowano
            </p>
            <p>
              <span class="material-icons md-36" style="color: red">
                close
              </span>
              - Odrzucono
            </p>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "../components/Navbar.vue";

export default {
  components: { Navbar },
  data() {
    return {
      name: "Elektroniczna baza sylabusów",
      userId: sessionStorage.employeeId,
      wydzialAdmin: sessionStorage.wydzialAdmin,
      przedmioty: [],
      przedmiotyDisplay: [],
      kierunki: [],
      kierunkiDisplay: [],
      lang: [],
      langDisplay: [],
      poziom: [],
      poziomDisplay: [],
      filter: "",
      columns: [],
      loading: true,
      filtry: false,
      headerFormalna: "Akceptacja formalna",
      message: "",
    };
  },
  methods: {
    show(e) {
      this.$refs.dialog.show();
      this.message = e;
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit("hide");
    },
    onCancelClick() {
      // we just need to hide the dialog
      this.hide();
    },
    checkRoute() {
      if (!sessionStorage.employeeId) {
        this.$router.push({
          name: "Login",
        });
      }
    },
    testowaFunkcja() {
      this.loading = true;
      this.przedmioty.splice(0);
      axios
        .get(
          `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/moje.php?email=${sessionStorage.employeeMail}&kierunki=${this.kierunkiDisplay}&jezyki=${this.langDisplay}&poziomy=${this.poziomDisplay}`
        )
        .then((response) => {
          let data = response.data.przedmioty;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.przedmioty.push(data[i]);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    loadPrzedmioty() {
      axios
        .get(
          `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/moje.php?email=${sessionStorage.employeeMail}`
        )
        .then((response) => {
          let data = response.data.przedmioty;
          let data2 = response.data.is_admin;
          // Tworznie filtrów
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.kierunki.push(data[i].field_of_study);
            this.lang.push(data[i].lang_version);
            this.poziom.push(data[i].degree);
          }
          let uniqKierunki = [...new Set(this.kierunki)];
          let uniqLang = [...new Set(this.lang)];
          let uniqPoziom = [...new Set(this.poziom)];
          this.kierunki = uniqKierunki;
          this.lang = uniqLang;
          this.poziom = uniqPoziom;
          this.kierunkiDisplay = this.kierunki;
          this.poziomDisplay = this.poziom;
          this.langDisplay = this.lang;
          // Koniec tworzenia filtrów
          if (data2 === true) {
            this.columns = [
              {
                name: "przedmiot",
                required: true,
                label: "Lista przedmiotów",
                align: "left",
                // style: "max-width: 40px",
                field: (row) => row.subject,
                sortable: true,
              },

              {
                name: "kierunek",
                required: true,
                label: "Kierunek",
                align: "left",
                field: (row) => row.field_of_study,
                sortable: true,
              },
              {
                name: "lang",
                required: true,
                label: "Wersja językowa",
                align: "center",
                field: (row) => row.lang_version,
                sortable: true,
              },
              {
                name: "poziom",
                required: true,
                label: "Poziom studiów",
                align: "center",
                field: (row) => row.degree,
                sortable: true,
              },
              {
                name: "formalna",
                required: true,
                label: "Akceptacja formalna",
                align: "center",
                field: (row) => row.formal_acceptance_at,
                sortable: true,
              },
              {
                name: "merytoryczna",
                required: true,
                label: "Akceptacja merytoryczna",
                align: "center",
                field: (row) => row.substantive_acceptance_at,
                sortable: true,
              },
              {
                name: "dziekan",
                required: true,
                label: "Akceptacja dziekana",
                align: "center",
                field: (row) => row.final_acceptance_at,
                sortable: true,
              },
              {
                name: "data",
                required: true,
                label: "Data dodania",
                align: "left",
                field: (row) => row.data,
                sortable: true,
              },
              {
                name: "dodal",
                required: true,
                label: "Kto dodał",
                align: "center",
                field: (row) => row.email,
                sortable: true,
              },
              {
                name: "szczegoly",
                required: true,
                label: "Szczegóły",
                align: "center",
                field: (row) => row.komentarz,
                sortable: true,
              },
            ];
          } else {
            this.columns = [
              {
                name: "przedmiot",
                required: true,
                label: "Lista przedmiotów",
                align: "left",
                field: (row) => row.subject,
                sortable: true,
              },
              {
                name: "kierunek",
                required: true,
                label: "Kierunek",
                align: "left",
                field: (row) => row.field_of_study,
                sortable: true,
              },
              {
                name: "lang",
                required: true,
                label: "Wersja językowa",
                align: "center",
                field: (row) => row.lang_version,
                sortable: true,
              },
              {
                name: "poziom",
                required: true,
                label: "Poziom studiów",
                align: "center",
                field: (row) => row.degree,
                sortable: true,
              },
              {
                name: "formalna",
                required: true,
                label: "Akceptacja formalna",
                align: "center",
                field: (row) => row.formal_acceptance_at,
                sortable: true,
              },
              {
                name: "merytoryczna",
                required: true,
                label: "Akceptacja merytoryczna",
                align: "center",
                field: (row) => row.substantive_acceptance_at,
                sortable: true,
              },
              {
                name: "dziekan",
                required: true,
                label: "Akceptacja dziekana",
                align: "center",
                field: (row) => row.final_acceptance_at,
                sortable: true,
              },
              {
                name: "data",
                required: true,
                label: "Data dodania",
                align: "left",
                field: (row) => row.data,
                sortable: true,
              },
              {
                name: "szczegoly",
                required: true,
                label: "Szczegóły",
                align: "center",
                field: (row) => row.komentarz,
                sortable: true,
              },
            ];
          }
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.przedmioty.push(data[i]);
          }
          this.loading = false;
        })
        .catch((e) => {});
    },
    loadFiltry() {
      axios
        .get(
          `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/moje.php?email=${sessionStorage.employeeMail}`
        )
        .then((response) => {
          let data = response.data.przedmioty;
          // for (let i = 0; i < Object.keys(data).length; i++) {
          //   this.kierunki.push(data[i].field_of_study);
          //   this.lang.push(data[i].lang_version);
          //   this.poziom.push(data[i].degree);
          // }
          // // this.kierunki = this.kierunki.filter((e) => e !== null);
          // // this.poziom = this.poziom.filter((e) => e !== null);
          // let uniqKierunki = [...new Set(this.kierunki)];
          // let uniqLang = [...new Set(this.lang)];
          // let uniqPoziom = [...new Set(this.poziom)];
          // this.kierunki = uniqKierunki;
          // this.lang = uniqLang;
          // this.poziom = uniqPoziom;
          // this.kierunkiDisplay = this.kierunki;
          // this.poziomDisplay = this.poziom;
          // this.langDisplay = this.lang;
        })
        .catch((e) => {});
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  mounted() {
    // this.loadFiltry();
    this.loadPrzedmioty();
  },
  created() {
    this.checkRoute();
  },
};
</script>

<style>
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.q-td.text-left {
  white-space: pre-wrap;
}
/* .q-td.text-left:hover {
  overflow: visible;
  white-space: normal;
  width: auto;
} */
</style>
