<template>
  <div class="row justify-center">
    <Spinner :showSpinner="showSpinner" :language="language" />
    <q-card v-if="!showSpinner" class="my-card text-center" style="width: 95%">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ language === "pl" ? nazwa : nazwaEN }}</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="q-pa-md" align="right">
          <q-breadcrumbs>
            <q-breadcrumbs-el icon="home" :to="'/' + language" />
          </q-breadcrumbs>
          <q-btn-toggle
            v-model="language"
            toggle-color="primary"
            :options="[
              { label: 'Pl', value: 'pl' },
              { label: 'En', value: 'en' },
            ]"
          />
        </div>
        <h6>
          {{
            language === "pl"
              ? "Proszę wybrać wydział"
              : "Please choose division"
          }}
        </h6>
        <div class="row justify-center">
          <div style="min-width: 200px">
            <q-select
              style="max-width: 200px"
              v-model="gdzie"
              :options="options"
              :label="language === 'pl' ? 'Wydział' : 'Division'"
              class="q-mb-md"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import Diacritics from "diacritic";
import Spinner from "../components/Spinner.vue";
export default {
  props: ["nazwa", "nazwaEN"],
  components: { Spinner },
  data() {
    return {
      lastPart: this.$route.path.split("/"),
      language: this.$route.path.split("/")[1],
      wydzialName: sessionStorage.wydzialName,
      wydzialUrl: sessionStorage.wydzialUrl,
      kierunekName: sessionStorage.kierunekName,
      kierunekUrl: sessionStorage.kierunekUrl,
      poziomName: sessionStorage.poziomName,
      poziomUrl: sessionStorage.poziomUrl,
      formaName: sessionStorage.formaName,
      formaUrl: sessionStorage.formaUrl,
      rokName: sessionStorage.rokName,
      rokUrl: sessionStorage.rokUrl,
      rodzajName: sessionStorage.rodzajName,
      rodzajUrl: sessionStorage.rodzajUrl,
      przedmiotName: sessionStorage.przedmiotName,
      przedmiotUrl: sessionStorage.przedmiotUrl,
      przedmiotFormaName: sessionStorage.przedmiotFormaName,
      przedmiotFormaUrl: sessionStorage.przedmiotFormaUrl,
      gdzie: "",
      options: [],
      errors: [],
      showSpinner: true,
      api: "",
    };
  },
  watch: {
    gdzie: function () {
      sessionStorage.setItem("wydzialName", this.gdzie);
      sessionStorage.setItem(
        "wydzialUrl",
        Diacritics.clean(this.gdzie).replace(/\s+/g, "-").toLowerCase()
      );
      this.$router.push({
        name: "Wydzial",
        params: {
          wydzial: Diacritics.clean(this.gdzie)
            .replace(/\s+/g, "-")
            .toLowerCase(),
        },
      });
    },
    language: function () {
      sessionStorage.setItem("language", this.language);
      this.$router.push("/" + this.language);
      if (this.language === "pl") {
        this.api = "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php";
      } else if (this.language === "en") {
        this.api = "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php";
      }
      this.options = [];
      axios
        .get(this.api)
        .then((response) => {
          let data = response.data.wydzialy;
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.options.push(Object.keys(data)[i]);
          }
          this.showSpinner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  mounted() {
    if (this.language === "pl") {
      this.api = "https://archiwum-sylabusy.umw.edu.pl/api/student/all.php";
    } else if (this.language === "en") {
      this.api = "https://archiwum-sylabusy.umw.edu.pl/api/student/all_en.php";
    }
    sessionStorage.language = this.$route.path.split("/")[1];
    this.showSpinner = true;
    axios
      .get(this.api)
      .then((response) => {
        let data = response.data.wydzialy;
        for (let i = 0; i < Object.keys(data).length; i++) {
          this.options.push(Object.keys(data)[i]);
        }
        this.showSpinner = false;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
};
</script>
