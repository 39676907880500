<template>
  <div class="row justify-center">
    <Spinner :showSpinner="showSpinner" :language="language" />
    <q-card v-if="!showSpinner" class="my-card text-center" style="width: 95%">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ language === "pl" ? nazwa : nazwaEN }}</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="q-pa-md" align="right">
          <q-breadcrumbs>
            <q-breadcrumbs-el icon="home" :to="'/' + language" />
            <q-breadcrumbs-el
              :label="wydzialName"
              :to="'/' + language + '/' + wydzialUrl"
            />
            <q-breadcrumbs-el
              :label="kierunekName"
              :to="'/' + language + '/' + wydzialUrl + '/' + kierunekUrl"
            />
            <q-breadcrumbs-el
              :label="poziomName"
              :to="
                '/' +
                language +
                '/' +
                wydzialUrl +
                '/' +
                kierunekUrl +
                '/' +
                poziomUrl
              "
            />
            <q-breadcrumbs-el
              :label="formaName"
              :to="
                '/' +
                language +
                '/' +
                wydzialUrl +
                '/' +
                kierunekUrl +
                '/' +
                poziomUrl +
                '/' +
                formaUrl
              "
            />
            <q-breadcrumbs-el
              :label="rokName"
              :to="
                '/' +
                language +
                '/' +
                wydzialUrl +
                '/' +
                kierunekUrl +
                '/' +
                poziomUrl +
                '/' +
                formaUrl +
                '/' +
                rokUrl
              "
            />
          </q-breadcrumbs>
          <q-btn-toggle
            v-model="language"
            toggle-color="primary"
            :options="[
              { label: 'Pl', value: 'pl' },
              { label: 'En', value: 'en' },
            ]"
          />
        </div>
        <div class="q-pa-md" v-if="dane">
          <q-table
            :title="
              language === 'pl' ? 'Lista przedmiotów' : 'List of subjects'
            "
            :rows="przedmioty"
            :columns="columns"
            :filter="filter"
            row-key="name"
          >
            <template v-slot:top-right>
              <q-input
                borderless
                dense
                debounce="300"
                v-model="filter"
                :placeholder="language === 'pl' ? 'Wyszukaj' : 'Search'"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:body-cell-przedmiot="props">
              <q-td :props="props">
                <a :href="props.row.url" target="_blank">{{
                  props.row.przedmiot
                }}</a>
              </q-td>
            </template>
            <template v-slot:body-cell-lang_version="props">
              <q-td v-if="language === 'pl'"></q-td>
              <q-td v-if="language === 'en'" :props="props">{{
                props.row.lang_version
              }}</q-td>
            </template>
          </q-table>
        </div>
        <div class="q-pa-md" v-if="!dane">
          <h5>{{ language === "pl" ? "Brak sylabusów" : "No syllabuses" }}</h5>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../components/Spinner.vue";
export default {
  props: ["nazwa", "nazwaEN"],
  components: { Spinner },
  data() {
    return {
      lastPart: this.$route.path.split("/"),
      language: sessionStorage.language,
      wydzialName: sessionStorage.wydzialName,
      wydzialUrl: sessionStorage.wydzialUrl,
      kierunekName: sessionStorage.kierunekName,
      kierunekUrl: sessionStorage.kierunekUrl,
      poziomName: sessionStorage.poziomName,
      poziomUrl: sessionStorage.poziomUrl,
      formaName: sessionStorage.formaName,
      formaUrl: sessionStorage.formaUrl,
      rokName: sessionStorage.rokName,
      rokUrl: sessionStorage.rokUrl,
      dane: true,
      filter: "",
      przedmioty: [],
      columns: [],
      errors: [],
      showSpinner: false,
      api: `https://archiwum-sylabusy.umw.edu.pl/api/student/sylabus.php`,
    };
  },
  watch: {
    language: function () {
      sessionStorage.setItem("language", this.language);
      this.$router.push("/" + this.language);
    },
  },
  mounted() {
    if (this.language === "pl") {
      this.columns = [
        {
          name: "przedmiot",
          required: true,
          label: "Lista przedmiotów",
          align: "left",
          field: (row) => row.przedmiot,
          sortable: true,
        },
      ];
    } else if (this.language === "en") {
      this.columns = [
        {
          name: "przedmiot",
          required: true,
          label: "List of subjects",
          align: "left",
          field: (row) => row.przedmiot,
          sortable: true,
        },
        {
          name: "lang_version",
          label: "Language version",
          align: "right",
          field: (row) => row.lang_version,
          sortable: true,
        },
      ];
    }
    this.api = `https://archiwum-sylabusy.umw.edu.pl/api/student/sylabus.php?wersja_api=${this.language}`;
    sessionStorage.language = this.$route.path.split("/")[1];
    this.showSpinner = true;
    if (
      !sessionStorage.wydzialName ||
      !sessionStorage.kierunekName ||
      !sessionStorage.poziomName ||
      !sessionStorage.formaName ||
      !sessionStorage.rokName
    ) {
      this.api = "https://archiwum-sylabusy.umw.edu.pl/api/student/names.php";
    }
    axios
      .get(this.api)
      .then((response) => {
        if (
          !sessionStorage.wydzialName ||
          !sessionStorage.kierunekName ||
          !sessionStorage.poziomName ||
          !sessionStorage.formaName ||
          !sessionStorage.rokName
        ) {
          sessionStorage.language = this.lastPart[1];
          sessionStorage.wydzialName = this.lastPart[2];
          sessionStorage.kierunekName = this.lastPart[3];
          sessionStorage.poziomName = this.lastPart[4];
          sessionStorage.formaName = this.lastPart[5];
          sessionStorage.rokName = this.lastPart[6];
          sessionStorage.wydzialUrl = sessionStorage.wydzialName;
          sessionStorage.kierunekUrl = sessionStorage.kierunekName;
          sessionStorage.poziomUrl = sessionStorage.poziomName;
          sessionStorage.formaUrl = sessionStorage.formaName;
          sessionStorage.rokUrl = sessionStorage.rokName;
          let wydzial = sessionStorage.wydzialName;
          let kierunek = sessionStorage.kierunekName;
          let poziom = sessionStorage.poziomName;
          let forma = sessionStorage.formaName;
          let rok = sessionStorage.rokName;
          sessionStorage.wydzialName = response.data["wydzialy-url"][wydzial];
          sessionStorage.kierunekName = response.data["kierunki-url"][kierunek];
          sessionStorage.poziomName = response.data["poziomy-url"][poziom];
          sessionStorage.formaName = response.data["forma-url"][forma];
          sessionStorage.rokName = response.data["rok-url"][rok];
          this.wydzialName = sessionStorage.wydzialName;
          this.kierunekName = sessionStorage.kierunekName;
          this.poziomName = sessionStorage.poziomName;
          this.formaName = sessionStorage.formaName;
          this.rokName = sessionStorage.rokName;
          this.$router.go();
          axios
            .get(
              `https://archiwum-sylabusy.umw.edu.pl/api/student/sylabus.php?wersja_api=${this.language}`
            )
            .then((response) => {
              let data =
                response.data.wydzialy[this.wydzialName].kierunki[
                  this.kierunekName
                ].poziomy[this.poziomName].forma[this.formaName].rok[
                  this.rokName
                ];
              for (let i = 0; i < Object.keys(data).length; i++) {
                this.przedmioty.push(data[i]);
              }
              this.showSpinner = false;
            })
            .catch((e) => {
              this.showSpinner = false;
              console.log(e.message);
              this.dane = false;
              this.errors.push(e);
            });
        } else {
          let data =
            response.data.wydzialy[this.wydzialName].kierunki[this.kierunekName]
              .poziomy[this.poziomName].forma[this.formaName].rok[this.rokName];
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.przedmioty.push(data[i]);
          }
          this.showSpinner = false;
        }
      })
      .catch((e) => {
        console.log(e.message);
        this.dane = false;
        this.showSpinner = false;
        this.errors.push(e);
      });
  },
};
</script>
<style>
a {
  text-decoration: none;
}
</style>
