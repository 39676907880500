<template>
  <div class="row justify-center">
    <q-card class="my-card text-center" style="width: 95%">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">{{ name }}</div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn class="bg-primary text-white q-mx-md q-my-md" to="/admin/dziekan"
          >Wróć</q-btn
        >
        <q-btn class="bg-primary text-white q-mx-md q-my-md" @click="logout"
          >Wyloguj się</q-btn
        >
      </q-card-actions>
      <q-card-section>
        <h6>Pola wypełnione w formularzu</h6>
        <q-form @submit.prevent="zaakceptuj" class="q-gutter-md">
          <div class="row justify-center">
            <div style="min-width: 200px; text-align: left">
              <p><b>Nazwa sylabusa:</b> {{ przedmiot.przedmiot }}</p>
              <p><b>Kierunek:</b> {{ przedmiot.kierunki }}</p>
              <p><b>Poziom:</b> {{ przedmiot.poziomy }}</p>
              <p><b>Forma:</b> {{ przedmiot.forma }}</p>
              <p><b>Rok:</b> {{ przedmiot.rok }}</p>
              <p><b>Data dodania:</b> {{ przedmiot.data }}</p>
              <p><a :href="przedmiot.url" target="_blank">Podgląd</a></p>
              <div style="min-width: 400px">
                <q-select
                  v-model="obiekt.status"
                  :options="options"
                  label="Zaakceptuj/Zwróć do korekty"
                  emit-value
                  map-options
                />
                <br />
                <q-input
                  label="Co należy poprawić?"
                  v-if="obiekt.status === 99"
                  outlined
                  type="textarea"
                  v-model="obiekt.komentarz"
                  emit-value
                  map-options
                />
              </div>
            </div>
          </div>
          <q-card-actions align="center">
            <q-btn class="bg-primary text-white" type="submit">Potwierdź</q-btn>
          </q-card-actions>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import { useQuasar } from "quasar";
export default {
  setup() {
    const $q = useQuasar();

    return {
      showNotif(position) {
        $q.notify({
          message: "Status został zmieniony",
          position: "bottom",
          color: "positive",
        });
      },
      showNotifWrong(mess) {
        $q.notify({
          message: mess,
          position: "bottom",
          color: "negative",
        });
      },
    };
  },
  data() {
    return {
      name: "Elektroniczna baza sylabusów",
      userId: sessionStorage.employeeId,
      userMail: sessionStorage.employeeMail,
      userName: sessionStorage.employeeName,
      id: this.$route.params.id,
      options: [
        { label: "Zaakceptowano", value: 3 },
        { label: "Zwrot do korekty", value: 99 },
      ],
      obiekt: {
        id: this.$route.params.id,
        mail: sessionStorage.employeeMail,
        status: this.status,
        komentarz: this.komentarz,
        etap: "dziekan",
      },
      przedmiot: {},
      wydzial: "",
      kierunek: "",
      poziom: "",
      forma: "",
    };
  },
  methods: {
    zaakceptuj() {
      axios
        .post(
          "https://archiwum-sylabusy.umw.edu.pl/api/pracownik/edit.php",
          this.obiekt,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.$router.push("/admin/dziekan");
          this.showNotif();
        })
        .catch((e) => {
          this.showNotifWrong(e.response.data.error);
        });
    },
    checkRoute() {
      if (!sessionStorage.employeeId) {
        this.$router.push({
          name: "Login",
        });
      }
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  mounted() {
    axios
      .get(
        `https://archiwum-sylabusy.umw.edu.pl/api/pracownik/dziekan.php?mail=${sessionStorage.employeeMail}&id=${this.id}`
      )
      .then((response) => {
        let data = response.data;
        if (data[0] === "redirect") {
          this.$router.push("/login");
        }
        this.przedmiot = data[0];
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  created() {
    this.checkRoute();
  },
};
</script>

<style></style>
